.App {
  max-width: 860px;
  margin: 40px auto;

}

button {
  background: none;
  border: 2px solid white;
  padding: 6px 12px;
  border-radius: 4px;
  color: white;
  font-weight: bold;
  cursor: pointer;
  font-size: 1em;
}

button:hover {
  background-color: #c23866;
  color: white
}

.card-grid {
  margin-top: 40px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 20px;
}