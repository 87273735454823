.card {
    position: relative;
}

.card img {
    width: 100%;
    object-fit: contain;
    display: block;
    border: 2px solid white;
    border-radius: 6px;
}

.card .front {
    position: absolute;
    transform: rotateY(90deg);
    transition: all ease-in 0.2s;
}

.flipped .front {
    transition-delay: 0.2s;
    transform: rotateY(0deg);
}

.card .back {
    transition: all ease-in 0.2s;
    transition-delay: 0.2s;
}

.flipped .back {
    transition-delay: 0s;
    transform: rotateY(90deg);
}